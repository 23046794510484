export default [
  {
    name: 'index',
    hidden: false,
    path: '/saas/',
    meta: {
      title: 'Dashboard',
      icon: 'cil-speedometer',
      affix: '',
      breadcrumb: true,
      noCache: false,
    },
  },
  {
    name: 'saas',
    path: '/saas/',
    meta: {
      title: 'Business Management',
      icon: 'cil-building',
    },
    children: [
      {
        name: 'saas',
        path: '/saas/',
        meta: {
          title: 'My online business',
          icon: 'folder-opened',
        },
      },
      {
        name: 'saas-add',
        path: '/saas/create/',
        hidden: true,
        meta: {
          title: 'Add Online Business',
          icon: 'plus',
        },
      },
      {
        name: 'saas-id',
        path: '/saas/:id/',
        hidden: true,
        meta: {
          title: 'Edit Online Business',
          icon: 'edit',
        },
      },
    ],
  },
  /*
  {
    path: '/users/',
    meta: {
      title: 'Users',
      icon: 'cil-people',
    },
    children: [
      {
        name: 'users',
        path: '/users/',
        meta: {
          title: 'List User',
          icon: 'folder-opened',
        },
      },
      {
        name: 'users-add',
        path: '/users/add/',
        meta: {
          title: 'Add User',
          icon: 'plus',
        },
      },
      {
        name: 'users-id',
        path: '/users/:id/',
        hidden: true,
        meta: {
          title: 'Edit User',
          icon: 'edit',
        },
      },
    ],
  },
  {
    path: '/courses/',
    meta: {
      title: 'Courses',
      icon: 'cil-excerpt',
    },
    children: [
      {
        name: 'courses',
        path: '/courses/',
        meta: {
          title: 'List Courses',
          icon: 'folder-opened',
        },
      },
      {
        name: 'courses-add',
        path: '/courses/add/',
        meta: {
          title: 'Add Course',
          icon: 'plus',
        },
      },
      {
        name: 'courses-id',
        path: '/courses/:id/',
        hidden: true,
        meta: {
          title: 'Edit Course',
          icon: 'edit',
        },
      },
    ],
  },
  */
]
