import Vue from 'vue'

if (!Vue.__tik_common_plugin__) {
  Vue.__tik_common_plugin__ = true
  Vue.mixin({
    data: () => ({
      wait: false,
    }),

    methods: {
      async withFeedback(fn, ...args) {
        // this.wait = true;
        // this.$root.is_loading(true);
        this.is_loading(true)
        try {
          await fn(...args)
        } catch (e) {
          console.error(e)
          this.$bvToast.toast('[' + e.name + '] ' + e.message, {
            autoHideDelay: 4000,
            solid: true,
            variant: 'danger',
          })
          // error('[' + e.name + '] ' + e.message)
        }
        // this.$root.is_loading(false);
        // this.wait = false;
        this.is_loading(false)
      },
      is_loading(v) {
        this.wait = !!v
        if (v) {
          document.body.classList.add('loading')
        } else {
          document.body.classList.remove('loading')
        }
      },
      setWithTimeout(k, val, nextVal, timeout) {
        this.$data[k] = val
        setTimeout(() => {
          this.$data[k] = nextVal
        }, timeout)
      },
    },
  })
}
