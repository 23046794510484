<template>
  <CFooter :fixed="false">
    <div class="col">
      <span class="ml-1">
        Copyright &copy; {{ new Date().getFullYear() }} All rights reserved |
        Tik Global Services
      </span>
    </div>
    <div class="ml-auto col-auto">
      <span class="mr-1">Provided by</span>
      <a href="https://tik-services.com" target="_blank">TIK Global Services</a>
    </div>
    <div class="col-auto">
      <b-form-select
        size="sm"
        :value="$root.$i18n.locale"
        :options="availableLocales"
        @change="$root.$i18n.setLocale($event)"
      ></b-form-select>
    </div>
    <div class="col-auto">
      <a
        v-if="fb_username"
        :href="`https://www.facebook.com/${fb_username}/`"
        class="pl-0 pr-2"
        title="Facebook page"
        ><fa :icon="['fab', 'facebook-f']"
      /></a>
      <a
        v-else-if="fb_url"
        :href="fb_url"
        class="pl-0 pr-2"
        title="Facebook page"
        ><fa :icon="['fab', 'facebook-f']"
      /></a>
      <a
        v-if="twitter_username"
        :href="`https://twitter.com/${twitter_username}`"
        class="pl-2 pr-2"
        title="Twitter page"
        ><fa :icon="['fab', 'twitter']"
      /></a>
      <a
        v-else-if="twitter_url"
        :href="twitter_url"
        class="pl-2 pr-2"
        title="Twitter page"
        ><fa :icon="['fab', 'twitter']"
      /></a>
      <a
        v-if="instagram_username"
        :href="`https://www.instagram.com/${instagram_username}/`"
        class="pl-2 pr-2"
        title="Instagram page"
        ><fa :icon="['fab', 'instagram']"
      /></a>
      <a
        v-else-if="instagram_url"
        :href="instagram_url"
        class="pl-2 pr-2"
        title="Instagram page"
        ><fa :icon="['fab', 'instagram']"
      /></a>
      <a
        v-if="linkedin_username"
        :href="`https://www.linkedin.com/in/${linkedin_username}/`"
        class="pl-2 pr-2"
        title="LinkedIn page"
        ><fa :icon="['fab', 'linkedin-in']"
      /></a>
      <a
        v-else-if="linkedin_url"
        :href="linkedin_url"
        class="pl-2 pr-2"
        title="LinkedIn page"
        ><fa :icon="['fab', 'linkedin-in']"
      /></a>
      <a
        v-if="youtube_username"
        :href="`https://www.youtube.com/user/${youtube_username}/`"
        class="pl-2 pr-2"
        title="YouTube page"
        ><fa :icon="['fab', 'youtube']"
      /></a>
      <a
        v-else-if="youtube_url"
        :href="youtube_url"
        class="pl-2 pr-2"
        title="YouTube page"
        ><fa :icon="['fab', 'youtube']"
      /></a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'FooterComponent',
  data: () => ({
    display_name: process.env.DISPLAY_NAME,
    fb_username: process.env.FB_USERNAME,
    twitter_username: process.env.TWITTER_USERNAME,
    instagram_username: process.env.INSTAGRAM_USERNAME,
    linkedin_username: process.env.LINKEDIN_USERNAME,
    youtube_username: process.env.YOUTUBE_USERNAME,
    fb_url: process.env.FB_URL,
    twitter_url: process.env.TWITTER_URL,
    instagram_url: process.env.INSTAGRAM_URL,
    linkedin_url: process.env.LINKEDIN_URL,
    youtube_url: process.env.YOUTUBE_URL,
  }),
  computed: {
    availableLocales() {
      return this.$root.$i18n.locales.map((l) => l.code)
      /* ({
        ...l,
        text: l.code,
        value: l.code,
        disabled: l.code === this.$root.$18n.locale,
      })) */
    },
  },
}
</script>
