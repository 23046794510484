<template>
  <div id="loader" ref="loader" class="show fullscreen">
    <div class="loader-container">
      <Logo style="max-height: 80px" class="img-fluid d-block mb-3 mx-auto" />
      <div class="loader-effect">Loading...</div>
    </div>
    <div class="loader-banner">
      {{ name }}
    </div>
  </div>
</template>
<script>
import Logo from '~~/components/Logo'
export default {
  name: 'CLoader',
  components: {
    Logo,
  },
  data: () => ({
    name: process.env.DISPLAY_NAME,
  }),
  methods: {
    hide() {
      this.$refs.loader.classList.remove('show')
    },
  },
}
</script>
<style lang="scss">
#loader {
  position: fixed;
  width: 96px;
  height: 96px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.24);
  border-radius: 16px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: opacity 0.2s ease-out, visibility 0s linear 0.2s;
  -o-transition: opacity 0.2s ease-out, visibility 0s linear 0.2s;
  transition: opacity 0.2s ease-out, visibility 0s linear 0.2s;
  z-index: 2000;
}

#loader.fullscreen {
  padding: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  background-color: #fff;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#loader.show {
  -webkit-transition: opacity 0.4s ease-out, visibility 0s linear 0s;
  -o-transition: opacity 0.4s ease-out, visibility 0s linear 0s;
  transition: opacity 0.4s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
  pointer-events: all;
  overflow: none;
}

#loader .loader-container {
  height: 120px;
  width: 120px;
  position: absolute;
  left: calc(50% - 60px);
  top: calc(50% - 60px);
}

.loader-effect,
.loader-effect:before,
.loader-effect:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  animation-fill-mode: both;
  animation: load7 1.8s infinite ease-in-out;
}

.loader-effect {
  color: #000;
  font-size: 10px;
  margin: 10px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.loader-effect:before,
.loader-effect:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader-effect:before {
  left: -3.5em;
  animation-delay: -0.32s;
}

.loader-effect:after {
  left: 3.5em;
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
.loader-banner {
  position: absolute;
  bottom: 40px;
  text-align: center;
  width: 100%;
  color: #000;
  font-size: 1.2em;
  font-weight: 700;
}
</style>
