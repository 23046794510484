<template>
  <div class="app">
    <website-header />
    <div class="app-body">
      <main class="main">
        <div
          class="app-container container-fluid mt-4"
          style="max-width: 1100px"
        >
          <nuxt class="site-section p-0" />
        </div>
        <website-footer />
      </main>
    </div>
  </div>
</template>

<script>
import WebsiteHeader from '~~/components/header'
import WebsiteFooter from '~~/components/footer'

export default {
  name: 'DefaultLayout',
  components: {
    WebsiteFooter,
    WebsiteHeader,
  },
  middleware: ['session', 'tracking'],
}
</script>

<style lang="scss">
// @import '~/node_modules/bootstrap-vue/dist/bootstrap-vue.css';
@import '~/assets/scss/style.scss';

@media (max-width: 575px) {
  .main .app-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
