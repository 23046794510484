<template>
  <fa icon="expand" @click="click" />
</template>

<script>
export default {
  name: 'Screenfull',
  methods: {
    click() {
      if (!document.fullscreenEnabled) {
        this.$bvToast.toast('Your browser does not allow fullscreen mode', {
          autoHideDelay: 3000,
          solid: true,
          variant: 'warning',
        })
        return false
      }
      // screenfull.toggle()
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen()
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        }
      }
    },
  },
}
</script>

<style scoped>
.screenfull-svg {
  display: inline-block;
  cursor: pointer;
  fill: #5a5e66;
  width: 20px;
  height: 20px;
  vertical-align: 10px;
}
</style>
