<template>
  <AppLogo class="app-logo" />
</template>

<script>
import AppLogo from '@app/components/Logo.vue'
export default {
  components: { AppLogo },
}
</script>

<style>
.app-logo {
  height: 100%;
  width: 100%;
  display: block;
  padding: 10px 20px !important;
}
</style>
