<template>
  <div :class="{ show: show }" class="header-search">
    <fa icon="search" class="search-icon" @click.stop="click" />
    <dropdown
      ref="headerSearchSelect"
      :options="options"
      class="header-search-select"
      placeholder="Search"
      :max-item="10"
      @selected="change"
      @filter="querySearch"
    ></dropdown>

    <!--
    <v-select
      ref="headerSearchSelect"
      v-model="search"
      :options="searchPool"
      autocomplete
      clearable
      searchable
      placeholder="Search"
      :close-on-select="true"
      :clear-search-on-select="true"
      :filter="querySearch"
      class="header-search-select"
      :get-option-label="itemLabel"
    >
    </v-select>
    <multiselect
      ref="headerSearchSelect"
      v-model="search"
      :options="searchPool"
      :close-on-select="true"
      :clear-on-select="false"
      :searchable="true"
      :custom-label="itemLabel"
      @input="change"
      placeholder="Search"
      track-by="path"
      :preselect-first="true"
      open-direction="bottom"
      :loading="isLoading"
      class="header-search-select"
    >
      :options="options"
	    @search-change="querySearch"
      :internal-search="false"
      <template slot="clear">
        <div
          class="multiselect__clear"
          v-if="search"
          @mousedown.prevent.stop="clear()"
        ></div>
      </template>
    </multiselect>
      -->
  </div>
</template>

<script>
// fuse is a lightweight fuzzy-search module
// make search results more in line with expectations
import Fuse from 'fuse.js'
import menu from '~/menu'
// import Multiselect from 'vue-multiselect'
// import vSelect from 'vue-select'
import Dropdown from 'vue-simple-search-dropdown/src/Dropdown.vue'

export default {
  name: 'HeaderSearch',
  components: {
    // Multiselect,
    // vSelect,
    Dropdown,
  },
  data() {
    return {
      isLoading: false,
      search: '',
      options: [],
      searchPool: [],
      show: false,
      fuse: undefined,
    }
  },
  computed: {
    routes() {
      return menu
    },
  },
  watch: {
    routes() {
      this.searchPool = this.generateRoutes(this.routes)
    },
    searchPool(list) {
      this.initFuse(list)
    },
    show(value) {
      /*
      if (value) {
        document.body.addEventListener('click', this.close)
      } else {
        document.body.removeEventListener('click', this.close)
      }
      */
    },
  },
  mounted() {
    this.searchPool = this.generateRoutes(this.routes)
  },
  methods: {
    itemLabel(item) {
      console.log(item)
      return item.title.join(' > ')
    },
    click() {
      this.show = !this.show
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.show) {
            this.querySearch(this.search)
            this.$refs.headerSearchSelect.showOptions()
            this.$refs.headerSearchSelect.focus()
          }
        }, 250)
      })
    },
    close() {
      this.$refs.headerSearchSelect.optionsShown = false
      this.$refs.headerSearchSelect.blur()
      this.search = ''
      this.options = []
      this.show = false
    },
    change(val) {
      this.$router.push(val.path)
      this.search = ''
      this.options = []
      this.$nextTick(() => {
        this.show = false
      })
    },
    clear() {
      this.search = ''
    },
    initFuse(list) {
      this.fuse = new Fuse(list, {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 0,
        keys: [
          {
            name: 'title',
            weight: 0.7,
          },
          {
            name: 'path',
            weight: 0.3,
          },
        ],
      })
    },
    // Filter out the routes that can be displayed in the sidebar
    // And generate the internationalized title
    generateRoutes(routes, basePath = '/', prefixTitle = []) {
      let res = []

      for (const router of routes) {
        // skip hidden router
        if (router.hidden) {
          continue
        }

        const data = {
          path: router.path,
          title: [...prefixTitle],
        }

        if (router.meta && router.meta.title) {
          data.title = [...data.title, router.meta.title]

          if (router.redirect !== 'noRedirect') {
            // only push the routes with title
            // special case: need to exclude parent router without redirect
            res.push(data)
          }
        }

        // recursive child routes
        if (router.children) {
          const tempRoutes = this.generateRoutes(
            router.children,
            data.path,
            data.title
          )
          if (tempRoutes.length >= 1) {
            res = [...res, ...tempRoutes]
          }
        }
      }
      for (const it of res) {
        it.name = it.title.join(' > ')
        it.id = it.path
      }
      return res
    },
    querySearch(query) {
      console.log('Search::', query)
      if (!!query && query !== '') {
        this.options = this.fuse.search(query).map((r) => r.item)
      } else {
        this.options = this.searchPool
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'vue-select/src/scss/vue-select.scss';

.header-search {
  .search-icon {
    cursor: pointer;
  }

  .header-search-select {
    transition: width 0.2s;
    width: 0;
    overflow-x: hidden;

    /*
    background: transparent;
    */
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;

    /*
    border-radius: 0;

    .el-input__inner {
      border-radius: 0;
      border: 0;
      padding-left: 0;
      padding-right: 0;
      box-shadow: none !important;
      border-bottom: 1px solid #d9d9d9;
      vertical-align: middle;
    }
    */
  }

  &.show {
    .header-search-select {
      width: 250px;
      margin-left: 10px;
      overflow-x: visible;
    }
  }
}
</style>
