import Router from 'vue-router'
import menu from './menu'

export function createRouter(ssrContext, createDefaultRouter, routerOptions) {
  const options = routerOptions || createDefaultRouter(ssrContext).options
  return new Router({
    ...options,
    routes: fixRoutes(options.routes),
  })
}

function traverse(routes, fn) {
  routes.forEach((r) => {
    fn(r)
    if (r.children) traverse(r.children, fn)
  })
}
function fixRoutes(defaultRoutes) {
  const menuRoutes = {}
  traverse(menu, (r) => {
    if (r.name) {
      menuRoutes[r.name] = r
    }
  })
  // default routes that come from `pages/`
  traverse(defaultRoutes, (r) => {
    const item = menuRoutes[r.name]
    if (item) {
      r.meta = item.meta
    } else {
      r.meta = {
        breadcrumb: false,
      }
    }
  })
  console.log('ROUTES:::', defaultRoutes)
  return defaultRoutes
}
