import Vue from 'vue'
// Register a global custom directive called `v-focus`
const autofocus = {
  // When the bound element is inserted into the DOM...
  inserted(el) {
    // Focus the element
    // console.log("autofocus", el);
    el.focus()
  },
}

Vue.directive('autofocus', autofocus)

// export default autofocus
