<template>
  <CDropdown
    v-if="user"
    in-nav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <CIcon name="cil-user" />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Settings</strong>
    </CDropdownHeader>
    <b-dropdown-item :to="localePath({ name: 'account' })"
      ><CIcon name="cil-user" /> {{ $t('Profile') }}</b-dropdown-item
    >
    <b-dropdown-item :to="localePath({ name: 'logout' })"
      ><CIcon name="cil-lock-locked" /> {{ $t('Logout') }}</b-dropdown-item
    >
  </CDropdown>
</template>

<script>
import AppHeaderDropdown from '@coreui/vue/src/components/dropdown/CDropdownHeader.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'DefaultHeaderDropdownAccnt',
  components: {
    AppHeaderDropdown,
  },
  computed: mapGetters({ user: 'auth/user' }),
}
</script>
<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
<i18n type="application/json">
{
  "en": {
    "Profile": "Profile",
    "Logout": "Logout"
  },
  "fr": {
    "Profile": "Compte",
    "Logout": "Déconnectez"
  }
}
</i18n>
