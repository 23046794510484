import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'
import VeeValidateFr from 'vee-validate/dist/locale/fr'

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  // errorBagName: 'veeErrors'
})

Validator.localize('fr', VeeValidateFr)

if (!Vue.__vee_validate_plugin__) {
  Vue.__vee_validate_plugin__ = true
  Vue.mixin({
    methods: {
      validateState(ref) {
        if (
          this.veeFields[ref] &&
          (this.veeFields[ref].dirty || this.veeFields[ref].validated)
        ) {
          return !this.errors.has(ref)
        }

        return null
      },
    },
  })
}
