<template>
  <div class="app">
    <website-header />
    <div class="app-body">
      <main class="main">
        <div class="app-container">
          <nuxt class="site-section p-0" />
        </div>
        <website-footer />
      </main>
    </div>
  </div>
</template>
<script>
import WebsiteFooter from '~~/components/footer'
import WebsiteHeader from '~~/components/header'

export default {
  name: 'PromoLayout',
  components: {
    WebsiteFooter,
    WebsiteHeader,
  },
  middleware: ['session', 'tracking'],
}
</script>

<style lang="scss"></style>
