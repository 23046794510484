/* eslint-disable no-unused-vars */
const ALL_ROLES = [
  'admin',
  'manager',
  'finance',
  'suivi',
  'commercial',
  'stock',
  'instructor',
]
const ACTIVE_ROLES = [
  'admin',
  'manager',
  'finance',
  'suivi',
  'commercial',
  'instructor',
]
const STAFFS = [
  'admin',
  'manager',
  'finance',
  'suivi',
  'commercial',
  'instructor',
]
const TOP_ADMINS = ['admin', 'manager']
const SUIVIS = ['admin', 'manager', 'finance', 'suivi']
const COMMERCIALS = ['admin', 'manager', 'finance', 'commercial']
const STOCK_TEAM = ['admin', 'manager', 'stock']
const POS_TEAM = ['admin', 'manager', 'pos']
/* eslint-enable no-unused-vars */

const AUTH = ($org) => ({
  index: true,
  saas: true,
  'saas-id': true,
  'saas-id-subscriptions': true,
  'saas-create': true,
  'account': true,
  'account-edit': true,
})

export default AUTH
