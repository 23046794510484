export default function maintenance({ redirect, route, env, app }) {
  if (route.query.auth === env.MAINTENANCE_TOKEN) {
    if (process.client && global.sessionStorage) {
      global.sessionStorage.maintenance = env.MAINTENANCE_TOKEN
    }
    return
  }
  if (process.client) {
    console.log(
      'Checking session maintenance:',
      global.sessionStorage.maintenance
    )
    if (
      !global.sessionStorage ||
      global.sessionStorage.maintenance === env.MAINTENANCE_TOKEN
    )
      return
  }
  if (process.client) {
    console.log(
      'Checking localStorage maintenance:',
      global.localStorage.maintenance
    )
    if (
      !global.localStorage ||
      global.localStorage.maintenance === env.MAINTENANCE_TOKEN
    )
      return
  }
  if (route.name && route.name.startsWith('maintenance')) return
  return redirect(app.localePath({ name: 'maintenance' }))
}
