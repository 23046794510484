<template>
  <div :class="{ 'c-app': true, 'c-dark-theme': darkMode }">
    <loader ref="loader" />
    <AppSidebar />
    <CWrapper>
      <AppHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <AppFooter />
    </CWrapper>
  </div>
</template>
<script>
import AppFooter from '~~/components/footer'
import AppHeader from '~~/components/app-header'
import AppSidebar from '~~/components/Sidebar.vue'
import Loader from '~~/components/loader.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardLayout',
  components: {
    Loader,
    AppFooter,
    AppSidebar,
    AppHeader,
  },
  middleware: ['auth', 'session', 'tracking'],
  computed: {
    ...mapGetters({
      darkMode: 'dashboard/darkMode',
    }),
  },
  async mounted() {
    await this.$store.dispatch('auth/loadCurrentUser')
    this.$refs.loader.$el.classList.remove('show')
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
