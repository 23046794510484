import { organization as $organization, setConfigOrganization } from '~/sdk'

export default function refreshOrganization({ route, app }) {
  if (process.client) {
    if ('live' in route.query) {
      console.log('Refreshing organization data...')
      $organization.refresh()
      setConfigOrganization($organization)
    }
  }
}
