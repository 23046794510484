<template>
  <CSidebar
    fixed
    :minimize="sidebarMinimize"
    :show="sidebarShow"
    @update:show="
      (value) => $store.commit('dashboard/set', ['sidebarShow', value])
    "
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <Logo name="logo" style="max-height: 35px" />
    </CSidebarBrand>

    <SidebarNav />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="
        $store.commit('dashboard/set', ['sidebarMinimize', !sidebarMinimize])
      "
    />
  </CSidebar>
</template>

<script>
import Logo from '~/components/Logo'
import SidebarNav from '~/components/SidebarNav'
import { mapGetters } from 'vuex'

export default {
  name: 'SidebarComponent',
  components: {
    Logo,
    SidebarNav,
  },
  computed: {
    ...mapGetters({
      sidebarShow: 'dashboard/sidebarShow',
      sidebarMinimize: 'dashboard/sidebarMinimize',
    }),
  },
}
</script>
