<template>
  <CRenderFunction flat :content-to-render="nav" />
</template>

<script>
import routes from '~/menu'

function generateNav() {
  const nav = [
    {
      _name: 'CSidebarNav',
      _children: routes
        .filter((route) => !route.hidden)
        .map((route) =>
          route.children
            ? {
                _name: 'CSidebarNavDropdown',
                name: route.meta.title,
                to: route.path,
                icon: route.meta.icon,
                items: route.children
                  .filter((c) => !c.hidden)
                  .map((c) => ({
                    name: c.meta.title,
                    to: c.path,
                  })),
              }
            : {
                _name: 'CSidebarNavItem',
                name: route.meta.title,
                to: route.path,
                icon: route.meta.icon,
              }
        ),
    },
  ]
  console.log('NAV::::', nav)
  return nav
}

export default {
  name: 'SidebarNavC',
  data: () => ({
    nav: generateNav(),
  }),
}
</script>
