export default function ({ error, redirect, route, app, store }) {
  try {
    if (store.state.auth.user) {
      route.meta.matomo = {
        userId: ['setUserId', store.state.auth.user.email],
      }
    }
  } catch (e) {
    error({
      message: 'Audience Analysis Error',
      statusCode: 500,
    })
  }
}
