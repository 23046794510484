<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('dashboard/toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('dashboard/toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <Logo class="c-sidebar-brand-full" alt="logo" style="height: 48px" />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
    <!--
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard"> Dashboard </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/users" exact>
          {{ $t('Users') }}
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink>
          {{ $t('Settings') }}
        </CHeaderNavLink>
      </CHeaderNavItem>
    -->
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <CHeaderNavItem
        v-if="install"
        ref="install"
        class="d-md-down-none btn btn-primary mx-2"
        title="Install"
        @click="installApp()"
      >
        <CHeaderNavLink>
          <fa icon="download" class="text-light" />
        </CHeaderNavLink>
      </CHeaderNavItem>

      <CHeaderNavItem class="d-md-down-none mx-2">
      <!--<Search />-->
      </CHeaderNavItem>

      <!--
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-bell" />
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list" />
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open" />
        </CHeaderNavLink>
      </CHeaderNavItem>
      -->

      <CHeaderNavItem class="d-md-down-none mx-2">
        <Screenfull />
      </CHeaderNavItem>
      <HeaderDropdownAccnt />
    </CHeaderNav>
    <CSubheader class="px-3">
      <HeaderBreadcrumb class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import HeaderDropdownAccnt from '../components/HeaderDropdownAccnt'
import Logo from '~/components/Logo'
import Screenfull from '~/components/Screenfull'
import Search from '~/components/HeaderSearch'
import HeaderBreadcrumb from '~/components/HeaderBreadcrumb'
import { mapGetters } from 'vuex'

export default {
  name: 'AppHeader',
  components: {
    HeaderDropdownAccnt,
    HeaderBreadcrumb,
    Screenfull,
    Search,
    Logo,
  },
  data() {
    return {
      install: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  mounted() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.install = e
    })
  },
  methods: {
    installApp() {
      this.install.prompt()
      // Wait for the user to respond to the prompt
      this.install.userChoice.then((choiceResult) => {
        this.install = null
      })
    },
  },
}
</script>

<i18n type="application/json">
{
  "en": {
    "Users": "Users",
    "Settings": "Settings"
  },
  "fr": {
    "Users": "Utilisateurs",
    "Settings": "Paramètres"
  }
}
</i18n>
