<template>
  <AppHeader fixed style="z-index: 1045">
    <SidebarToggler v-if="toggler" class="d-lg-none" display="md" mobile />
    <b-link
      :to="localePath({ name: 'index' })"
      title="Home page"
      class="navbar-brand etakwin"
      style="font-size: 24px"
    >
      <Logo style="padding: 20px" />
    </b-link>
    <SidebarToggler v-if="toggler" class="d-md-down-none" display="lg" />
    <b-navbar-nav class="d-md-down-none">
      <b-nav-item :to="localePath({ name: 'learn' })" class="px-3">{{
        $t('Courses')
      }}</b-nav-item>
      <b-nav-item :to="localePath({ name: 'webinars' })" class="px-3">{{
        $t('Webinars')
      }}</b-nav-item>
      <b-nav-item :to="localePath({ name: 'blog' })" class="px-3">{{
        $t('Blog')
      }}</b-nav-item>
      <b-nav-item :to="localePath({ name: 'profile' })" class="px-3">{{
        $t('Account')
      }}</b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav class="ml-auto">
      <!--
      <b-nav-item
        ref="install"
        v-if="install"
        @click="installApp()"
        class="d-md-down-none btn btn-primary mr-1"
        title="Install"
      >
        <fa icon="download" class="text-light" />
      </b-nav-item>
      -->
      <b-nav-item
        v-if="!user"
        :to="localePath({ name: 'login', query: { next: $route.fullPath } })"
        :link-attrs="{
          title: $t('Create your Account'),
        }"
        class="nav-link register-btn rounded-pill mr-2"
        link-classes="text-white text-uppercase"
      >
        <span class="d-md-block d-none px-2"
          >{{ $t('Create your Account') }}
          <fa icon="chevron-right" class="ml-1" />
        </span>
        <span class="d-md-none d-md-down-block">
          <fa icon="sign-in-alt" />
        </span>
      </b-nav-item>
      <HeaderDropdownAccnt />
    </b-navbar-nav>
    <!--
      <AsideToggler class="d-none d-lg-block" />
      -->
    <!--<AsideToggler class="d-lg-none" mobile />-->
  </AppHeader>
</template>
<script>
import AppHeader from '@coreui/vue/src/components/template/CHeader.vue'
import HeaderDropdownAccnt from '../components/HeaderDropdownAccnt'
import SidebarToggler from '~/components/Sidebar/SidebarToggler'
import Logo from '../components/Logo'
import { mapGetters } from 'vuex'

export default {
  components: {
    AppHeader,
    HeaderDropdownAccnt,
    SidebarToggler,
    Logo,
  },
  props: {
    toggler: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      install: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  mounted() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.install = e
    })
  },
  methods: {
    installApp() {
      this.install.prompt()
      // Wait for the user to respond to the prompt
      this.install.userChoice.then((choiceResult) => {
        this.install = null
      })
    },
  },
}
</script>

<style lang="scss">
.register-btn {
  background-color: #ec5252;
  border: 1px solid transparent;
  color: white !important;
}
</style>

<i18n type="application/json">
{
  "en": {
    "Courses": "Courses",
    "Create your Account": "Create your Account",
    "Contact": "Contact",
    "completed": "completed",
    "Account": "Account",
    "contact": "contact",
    "contact us": "contact us",
    "sign in": "sign in",
    "join for free": "join for free",
    "start for free": "start for free",
    "our courses": "our courses",
    "stay up to date": "stay up to date",
    "Enter your email address": "Enter your email address",
    "subscribe": "subscribe",
    "team": "team",
    "instructors": "instructors",
    "people says": "people says",
    "testimonials": "testimonials",
    "contact form": "contact form",
    "get in touch": "get in touch",
    "first name": "first name",
    "last name": "last name",
    "email": "email",
    "phone": "phone",
    "message": "message",
    "Write your notes or questions here...": "Write your notes or questions here...",
    "send message": "send message",
    "about us": "about us",
    "quick links": "quick links",
    "follow us": "follow us",
    "profile": "profile",
    "language": "language",
    "fetching courses": "fetching courses"
  },
  "fr": {
    "Courses": "Cours",
    "Create your Account": "Créez votre Compte",
    "Contact": "Contact",
    "completed": "completés",
    "Account": "Compte",
    "contact": "contact",
    "contact us": "contactez-nous",
    "sign in": "se connecter",
    "join for free": "abonnez-vous gratuitement",
    "start for free": "commencez gratuitement",
    "our courses": "nos cours",
    "stay up to date": "restez à jour",
    "Enter your email address": "Saisissez votre adresse email",
    "subscribe": "abonnez",
    "team": "équipe",
    "instructors": "formateurs",
    "people says": "le peuple a dit",
    "testimonials": "témoignages",
    "contact form": "formulaire de contact",
    "get in touch": "communiquez avec nous",
    "first name": "Prénom",
    "last name": "Nom",
    "email": "email",
    "phone": "téléphone",
    "message": "message",
    "Write your notes or questions here...": "Envoyez-nous vos commentaires, suggestions ou toutes questions...",
    "send message": "envoyez",
    "about us": "à propos de nous",
    "quick links": "liens rapides",
    "follow us": "suivez-nous",
    "profile": "compte",
    "language": "langage",
    "fetching courses": "récupération cours"
  }
}
</i18n>
