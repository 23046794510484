export const state = () => ({
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  darkMode: true,
})

export const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  toggleDarkModel(state) {
    state.darkMode = !state.darkMode
  },
  set(state, [variable, value]) {
    state[variable] = value
  },
}

export const getters = {
  sidebarShow: (state) => state.sidebarShow,
  sidebarMinimize: (state) => state.sidebarMinimize,
  darkMode: (state) => state.darkMode,
}
