export default function ({
  error,
  redirect,
  route,
  app,
  store,
  $organization,
  $hasViewPerm,
}) {
  if (!store.state.auth.user) {
    error({
      message: 'You are not connected',
      statusCode: 403,
    })
    return redirect(
      app.localePath({
        name: 'login',
        query: { next: route.fullPath },
      })
    )
  }
  if (!$hasViewPerm(store.state.auth.user, route.name)) {
    error({
      message: 'You have no permissions to view this page',
      statusCode: 403,
    })
  }
}
